vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Rinomodelacion</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <!-- <img class="img-fluid" alt="rinomodelación" src="../../assets/beatriz-ayllon-home.png" /> -->
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            La <span class="p-text-bold">rinomodelación</span> es un tratamiento médico estético
                            revolucionario que ofrece una solución no quirúrgica para remodelar la nariz de manera segura y
                            efectiva. Con este procedimiento, podemos corregir y elevar la punta de la nariz, logrando
                            resultados sorprendentes sin necesidad de pasar por el quirófano.
                        </p>
                        <p>
                            Mediante la aplicación de relleno en áreas específicas de la nariz, podemos corregir <span
                                class="p-text-bold">depresiones</span>, armonizar <span
                                class="p-text-bold">asimetrías</span> y <span class="p-text-bold">resaltar la punta</span>.
                            Este enfoque personalizado nos permite crear una apariencia equilibrada y proporcionada,
                            mejorando la estética facial de nuestros pacientes de forma natural y duradera.
                        </p>
                        <p>
                            Nuestro procedimiento de rinomodelación se realiza con <span class="p-text-bold">anestesia
                                local</span>, lo que garantiza la comodidad y seguridad del paciente durante todo el
                            proceso. Los resultados son <span class="p-text-bold">instantáneos</span> y <span
                                class="p-text-bold">espectaculares</span>, sin necesidad de preocuparse por <span
                                class="p-text-bold">cicatrices</span> o <span class="p-text-bold">heridas</span>. Además, al
                            ser un tratamiento no invasivo, el tiempo de recuperación es mínimo y los pacientes pueden
                            retomar sus actividades diarias de inmediato.
                        </p>
                        <p>
                            Si estás buscando una alternativa no quirúrgica para mejorar la apariencia de tu nariz, nuestra
                            rinomodelación es la opción perfecta. Nuestro equipo de expertos en estética facial está
                            comprometido en brindarte resultados excepcionales y una experiencia personalizada. ¡No dudes en
                            contactarnos y descubre cómo podemos transformar tu apariencia de manera segura y efectiva!
                        </p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'RinomodelacionVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>